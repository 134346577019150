import React, { useEffect, useRef } from 'react';
import { Renderer, CanvasEvent } from '../renderer';
export { CanvasEvent }

export type Message = { type: 'message' | 'info' | 'error', value: string };

type CanvasProps = {
  message: Message | undefined,
  handleInput: (event: CanvasEvent) => void,
} & React.PropsWithChildren<{}>;

export function Canvas({ message, handleInput }: CanvasProps): JSX.Element {

  const canvas = useRef<HTMLCanvasElement>(null);
  const renderer = useRef<Renderer>();

  // Put this in a ref so we don't have to re-add listners every render
  // Warning: might get race conditions when the callback is invoked, and when it is updated in useEffect
  const boxedCallback = useRef(handleInput);
  useEffect(() => {
    boxedCallback.current = handleInput;
  }, [handleInput]);

  useEffect(() => {
    // Note: ref for canvas is already defined when this is called
    renderer.current = new Renderer(canvas.current!);

    // redirect events from the renderer to the function passed down by the parent
    renderer.current.addEventListener('click', ((event: CanvasEvent) => {
      boxedCallback.current(event);
    }) as EventListener);

    renderer.current.start();
    
    return () => renderer.current?.stop();
  }, []);

  useEffect(() => {

    if (!message) return;

    renderer.current!.renderText(message);

  }, [message]);

  return (
    <canvas ref={canvas} className="Canvas"/>
  );
}