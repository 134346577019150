import React, { useEffect, useRef, useState, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import './App.css';

import { Canvas, CanvasEvent, Message } from './components/Canvas';

// TODO: use inline worker-loader until CRA supports Workers https://github.com/facebook/create-react-app/issues/3660
// Note: options denoted as query params
/* eslint-disable import/no-webpack-loader-syntax */
import SharedWorker from 'worker-loader?worker=SharedWorker!./worker';

const AdminPage = lazy(() => import('./components/AdminPage'));
const Loading = () => (<div>Loading...</div>);

// TODO error handling when failing to lazy load
function App() {

  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route exact path="/" render={() => <MainPage />} />
          <Route exact path="/admin" render={() => <AdminPage />} />
          <Route exact path="/callback">
            <Loading />
          </Route>
          <Redirect to="/" />
        </Switch>
      </Suspense>
    </Router>
  );
}

// export type Message = { type: 'message' | 'info' | 'error', value: string };
function MainPage(): JSX.Element {

  const [connectionState, setConnectionState] = useState<'open' | 'connecting' | 'closed'>('connecting');
  const [message, setMessage] = useState<Message>({ type: 'info', value: 'Connecting...' });

  const sharedWorker = useRef<SharedWorker>()

  // try to connect on page load, if unable to do so, show button to retry
  useEffect(() => {

    // instantiate shared worker
    sharedWorker.current = new SharedWorker();

    // called when an ErrorEvent of type error bubbles through the worker
    sharedWorker.current.onerror = (error) => {
      console.error(error);
    }

    sharedWorker.current.port.onmessage = (event) => {
      const { type, value } = event.data;

      switch (type) {

        // this particular message is sent when there is already an open EventSource connection in the SharedWorker
        case 'open': {
          setConnectionState('open');
          setMessage({ type: 'message', value });
          break;
        }
          
        case 'reconnect': {
          setConnectionState('connecting');
          setMessage({ type: 'info', value });
          break;
        }

        case 'info': {
          console.log(value);
          break;
        }

        case 'error': {
          setConnectionState('closed');
          console.error(value);
          break;
        }

      }
    };

    // this fires when sharedWorker receives a message that cannot be deserialized
    sharedWorker.current.port.onmessageerror = (error) => {
      console.log(error.data);
    }

    // subscribe to broadcast channel used by sharedWorker
    const broadcastChannel = new BroadcastChannel('sharedWorkerChannel');
    broadcastChannel.onmessage = (event) => {

      const { type, value } = event.data;

      // send data that can be consumed by canvas
      switch (type) {

        case 'open': {
          setConnectionState('open');
          setMessage({ type: 'message', value });
          break;
        }

        case 'message': {
          setMessage({ type: 'message', value });
          break;
        }

        case 'error': {
          setMessage({ type: 'error', value });
          setConnectionState('closed'); // TODO hide button until ui updates
          break;
        }

        case 'debug': {
          console.log(value);
          break;
        }
      }

    };

    // this fires when broadcastChannel receives a message that cannot be deserialized
    broadcastChannel.onmessageerror = (event) => {
      console.error(event.data)
    };

    return () => {
      broadcastChannel.close();
    }

  }, []);
  
  const handleInput = (event: CanvasEvent) => {
    const { data } = event; // button type

    switch (data) {
      case 'reconnect': {
        // Note: we are also checking if source is closed in the worker in case a retry connection is queued before connectionState is closed
        if (connectionState === 'closed') {
          // TODO implement increasing timer
          sharedWorker.current!.port.postMessage({ type: 'reconnect' });
        }
        break;
      }
    }

  };

  return (
    <div className="App">
      <Canvas message={message} handleInput={handleInput}></Canvas>
    </div>
  )
}

export default App;
