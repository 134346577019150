import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import * as serviceWorker from './serviceWorkerRegistration';

const REQUIRED_FEATURES = ['BroadcastChannel', 'SharedWorker'];

/**
 * Returns true if the feature is supported by the broswer
 */
function isSupported(feature: string): boolean {
  switch (feature) {

    case 'BroadcastChannel': {
      return typeof BroadcastChannel !== undefined;
    }
      
    case 'SharedWorker': {
      return typeof SharedWorker !== undefined;
    }
      
    default: {
      throw Error(`No feature detection function for ${feature}`)
    }
  }
}

const missingFeatures = REQUIRED_FEATURES.filter((value => !isSupported(value)));
const hasRequiredFeatures = missingFeatures.length === 0;

ReactDOM.render(
  <React.StrictMode>
    {
      hasRequiredFeatures ?
        <App /> :
        <div>
          Your browser needs to support the following Web APIs for this app to work:
           <ul>
              {missingFeatures.map(value => <li key={value}>{value}</li>)}
           </ul>
        </div>
    }
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// serviceWorker.register();
