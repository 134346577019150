import { throttle } from 'lodash-es';

import { Message } from './components/Canvas';

interface MessageObjectProperties {
  text: string,
  lines: string[],
  fontSize: number,
  opacity: number,
  timePosted: number,
  effect: number,
  timeFirstVisible: number,
  startedAnimation: boolean,
  finishedAnimation: boolean,
}; // TODO enum types
class MessageObject {

  static STAY_EFFECT = 0;
  static FADE_EFFECT = 1;

  // need to set this initially
  needsRender = false;

  private static MAX_TIME_VISIBLE = 2000;

  private messages: MessageObjectProperties[] = [];
  private currMessage: MessageObjectProperties | undefined;

  pushMessage(message: Message, ctx: CanvasRenderingContext2D) {

    let properties: { effect: number, opacity: number };

    // set the effects based on the type
    switch (message.type) {

      case 'error': {
        properties = { effect: MessageObject.STAY_EFFECT, opacity: 0.1 };
        break;
      }

      default: {
        properties = { effect: MessageObject.FADE_EFFECT, opacity: 0.1 };
      }
    }

    // set fontsize relative to canvas
    const fontSize = this.getFontSizeFromHeight(ctx.canvas.height);
    // console.log(ctx.canvas.width, ctx.canvas.height);

    ctx.font = `bold ${fontSize}px arial`

    // multiline text wrapping
    const lines = this.getTextWrapping(message.value, ctx)

    this.messages.push({
      text: message.value,
      lines, 
      fontSize,
      timePosted: performance.now(),
      timeFirstVisible: 0,
      startedAnimation: false,
      finishedAnimation: false,
      ...properties
    });

    if (this.messages.length === 1) {
      this.currMessage = this.messages[0];
    }

    this.needsRender = true;
  }

  // layout
  updateLayout(ctx: CanvasRenderingContext2D) {
    
    const newFontSize = this.getFontSizeFromHeight(ctx.canvas.height);
    ctx.font = `bold ${newFontSize}px arial`
    for (let message of this.messages) {
      message.fontSize = newFontSize;
      // it's ok to leave this out for some reason
      // message.lines = this.getTextWrapping(this.currMessage.text, ctx);
    }

    if (this.currMessage) {
      this.currMessage.fontSize = newFontSize;
      this.currMessage.lines = this.getTextWrapping(this.currMessage.text, ctx);
    }

    this.needsRender = true;
  }

  // update animations
  update(currTime: number) {

    // length becomes zero because we shift the message
    if (this.messages.length === 0) {
      // console.log('zero messages');
      this.needsRender = false;
    } else {

      // Note: effects must indicate when animation has finished, when text has been fully visible
      switch (this.currMessage!.effect) {

        // TODO fade based on time visible
        case MessageObject.FADE_EFFECT: {

          if (this.currMessage!.timeFirstVisible === 0) {

            // fade in
            this.currMessage!.opacity = Math.min(1.0, this.currMessage!.opacity + 0.05);

            if (this.currMessage!.opacity === 1.0) {
              this.currMessage!.timeFirstVisible = currTime;
            }
          } else if ((currTime - this.currMessage!.timeFirstVisible) > MessageObject.MAX_TIME_VISIBLE) {
            // if text was visible for more than the max time, start fading out
            this.currMessage!.opacity = Math.max(0, this.currMessage!.opacity - 0.07);

            if (this.currMessage!.opacity === 0) {
              this.currMessage!.finishedAnimation = true;
            }
          }
          break;
        }

        // TODO max time visible for this effect
        case MessageObject.STAY_EFFECT: {
          if (!this.currMessage!.startedAnimation) {
            this.currMessage!.startedAnimation = true;
          }
          // just fade in
          this.currMessage!.opacity = Math.min(1.0, this.currMessage!.opacity + 0.05);

          if (this.currMessage!.opacity === 1.0) {
            this.currMessage!.finishedAnimation = true;
            this.currMessage!.timeFirstVisible = currTime;
          }

        }
      }

      // move on to the next message if animation is finished
      if (this.currMessage!.finishedAnimation) {
        this.messages.shift();
        if (this.messages.length > 0) {
          this.currMessage = this.messages[0];
          this.currMessage.startedAnimation = true;
        }
      }

    }

  }

  render(ctx: CanvasRenderingContext2D) {

    if (this.currMessage) {
      // TODO font size based on screen
      ctx.textBaseline = 'top';
      // ctx.font = `bold ${this.currMessage.fontSize}px arial`
      ctx.fillStyle = `rgba(0, 0, 0, ${this.currMessage.opacity})`;

      let y = 0;
      for (const text of this.currMessage.lines) {
        ctx.fillText(text, 0, y);
        y += this.currMessage.fontSize;
      }
    }

  }

  private getFontSizeFromHeight(height: number): number {
    return height / 6;
  }

  // TODO just output index numbers on where to split the text
  private getTextWrapping(text: string, ctx: CanvasRenderingContext2D): string[] {

    if (text === '') return [];

    const maxWidth = ctx.canvas.width;

    const sizes: [string, number][] = text.split(' ')
      .map(word => [word + ' ', ctx.measureText(word + ' ').width]);

    let [currentLine, currentWidth] = sizes.shift()!;
    let lines: string[] = [currentLine];

    for (const [word, width] of sizes) {
      if (currentWidth + width >= maxWidth) {
        lines.push(word);
        currentLine = word;
        currentWidth = width;
      } else {
        lines[lines.length - 1] += word;
        currentWidth += width;
      }
    }

    return lines;

  }
}

export class CanvasEvent extends Event {
  constructor(type: string, public data: any) {
    super(type);
  }
}

// TODO: EventTarget not generic https://github.com/microsoft/TypeScript/issues/33047 https://github.com/microsoft/TypeScript/issues/28357
export class Renderer extends EventTarget {

  static FPS = 1000.0 / 60.0;

  private lastDrawTime = 0.0;
  private paused = false;
  private animationID: number = 0;
  private ctx: CanvasRenderingContext2D;

  private resizeObserver: any;

  private textObject = new MessageObject();

  private onStop = new AbortController();

  // TODO: dynamic sizing
  private reconnectButton = {
    isActive: true
  }

  constructor(private canvas: HTMLCanvasElement) {
    super();
    this.ctx = canvas.getContext('2d')!;

    // @ts-ignore TODO remove this when ts version is 4.2.2
    this.resizeObserver = new ResizeObserver(throttle((entries: any) => {
      for (const entry of entries) {
        const { width, height } = getSizeFromEntry(entry);
        // Note: sometimes this fires twice 
        this.resize(width, height);
      }
    }, 200, { leading: true }));

    this.resizeObserver.observe(canvas, { box: 'content-box' });

    canvas.addEventListener('click', (event) => {

      // console.log(canvas.clientWidth, canvas.clientHeight);
      // console.log(event.clientX, event.clientY); // includes border
      // console.log(event.offsetX, event.offsetY); // excludes border

      // TODO: support for multiple buttons
      if (this.reconnectButton.isActive) {
        this.dispatchEvent(new CanvasEvent('click', 'reconnect'));
      }

    }, { signal: this.onStop.signal } as EventListenerOptions);

  }

  start() {
    requestAnimationFrame((currTime: number) => {
      this.tick(currTime);
    });
  }

  pause() {
    cancelAnimationFrame(this.animationID);
    this.paused = true;
  }

  resume() {
    this.paused = false;
    requestAnimationFrame((currTime: number) => {
      this.tick(currTime);
    });
  }

  tick(currTime: number) {
    
    this.animationID = requestAnimationFrame((currTime: number) => {
      this.tick(currTime);
    });

    if (this.paused || !this.textObject.needsRender) return;

    if (currTime >= this.lastDrawTime + Renderer.FPS) {
      this.lastDrawTime = currTime;

      // update objects
      this.textObject.update(currTime);

      // clear the canvas
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

      // render objects
      this.textObject.render(this.ctx);

      // post-render updates and optimizations
      if (!this.textObject.needsRender) {
        console.log('paused');
        this.pause();
      }

    }
  }

  resize(width: number, height: number) {

    // update canvas size
    this.canvas.width = width;
    this.canvas.height = height;

    // update objects
    this.textObject.updateLayout(this.ctx);

    // render objects
    this.textObject.render(this.ctx);
  }

  stop() {
    cancelAnimationFrame(this.animationID);
    this.resizeObserver.disconnect();
    this.onStop.abort();
  }

  renderText(message: Message) {
    if (message === undefined) return;
    console.log(message);
    this.textObject.pushMessage(message, this.ctx);

    if (this.paused) {
      // renderer can be paused in the post-render optimations in tick method
      this.resume();
    }
  }
}

/**
 * @see https://webglfundamentals.org/webgl/lessons/webgl-resizing-the-canvas.html
 * TODO: type for entry when ts version is 4.2.2
 */
function getSizeFromEntry(entry: any): { width: number, height: number } {
  let width: number;
  let height: number;
  let dpr = window.devicePixelRatio;

  if (entry.devicePixelContentBoxSize) {
    // NOTE: Only this path gives the correct answer
    // The other paths are an imperfect fallback
    // for browsers that don't provide anyway to do this
    width = entry.devicePixelContentBoxSize[0].inlineSize;
    height = entry.devicePixelContentBoxSize[0].blockSize;
    dpr = 1; // it's already in width and height
  } else if (entry.contentBoxSize) {
    if (entry.contentBoxSize[0]) {
      width = entry.contentBoxSize[0].inlineSize;
      height = entry.contentBoxSize[0].blockSize;
    } else {
      // legacy
      width = entry.contentBoxSize.inlineSize;
      height = entry.contentBoxSize.blockSize;
    }
  } else {
    // legacy
    width = entry.contentRect.width;
    height = entry.contentRect.height;
  }
  width = Math.round(width * dpr);
  height = Math.round(height * dpr);

  return { width, height };
}